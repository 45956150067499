<template>
<div class="wrapper"> 
    <div class="top-header">
        <div class="flex items-center gap-3">
            <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </button>
            <h1>{{ $route.params.id ? res1.name_cyr : $t('add_new_canals') }}</h1>
        </div>
    </div> 
    <div class="wp-body"> 
        <div class="uform" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <el-form :model="res1" :rules="rules1" ref="res1" class="Form">
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{$t('canal_name')}} </p>
                                <el-form-item prop="name_cyr">
                                    <el-input name="canal_name" :disabled="disabled1" v-model="res1.name_cyr" :placeholder="$t('canal_name')" clearable />
                                </el-form-item>
                            </label>

                            <label class="item">
                                <p>{{ $t('canal_org_name') }}</p>
                                <el-form-item prop="organization_name">
                                    <el-input name="organization_name" :disabled="disabled1" v-model="res1.organization_name" :placeholder="$t('canal_org_name')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('canal_region')}} </p>
                                <el-form-item prop="region">
                                    <el-select name="canal_region" :disabled="disabled1" v-model="res1.region" :loading="loading" class="w-full" :placeholder="$t('canal_region')">
                                        <el-option v-for="item in dictionary.region" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{ $t('canal_district') }}</p>
                                <el-form-item prop="district">
                                    <el-select name="canal_district" :disabled="disabled1" v-model="res1.district" class="w-full" clearable :placeholder="$t('canal_district')">
                                        <el-option v-for="item in current_district" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('canal_adress')}} </p>
                                <el-form-item prop="address">
                                    <el-input name="canal_adress" :disabled="disabled1" v-model="res1.address" :placeholder="$t('canal_adress')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('canal_year_of_construction')}} </p>
                                <el-form-item prop="area_watersource">
                                    <el-date-picker  name="canal_year_of_construction" :disabled="disabled1" style="width: 100%" v-model="res1.year_of_construction" type="year" value-format="yyyy" :placeholder="$t('canal_year_of_construction')">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('canals_date_of_accept')}}</p>
                                <el-form-item prop="date_of_accept">
                                    <el-date-picker name="date_of_accept" :disabled="disabled1" style="width: 100%" v-model="res1.date_of_accept" value-format="dd.MM.yyyy" format="dd.MM.yyyy" :placeholder="$t('canals_date_of_accept')">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{$t('canals_water_source')}} </p>
                                <el-select :disabled="disabled1" v-model="res1.water_source" class="w-full" clearable :placeholder="$t('canals_water_source')">
                                    <el-option name="canals_water_source" v-for="item in dictionary.watersource" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                    </el-option>
                                </el-select>
                            </label>
                            <label class="item">
                                <p>{{$t('canals_reservoir_class') }}</p>
                                <el-form-item prop="reservoir_class">
                                    <el-select name="canals_reservoir_class" :disabled="disabled1" v-model="res1.reservoir_class" class="w-full" clearable :placeholder="$t('canals_reservoir_class')">
                                        <el-option v-for="item in dictionary.reservoirclass" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>

                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{$t('water_base_buildingseismicity')}} </p>
                                <el-form-item prop="buildingseismicity">
                                    <el-select name="water_base_buildingseismicity" :disabled="disabled1" class="w-full" v-model="res1.buildingseismicity" clearable  :placeholder="$t('finalconclusion')" >
                                        <el-option v-for="item in dictionary.buildingseismicity" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{$t('water_base_earthquake_tolerance')}} </p>
                                <el-form-item prop="earthquake_tolerance">
                                    <el-input name="water_base_earthquake_tolerance" :disabled="disabled1" v-model="res1.earthquake_tolerance" :placeholder="$t('water_base_earthquake_tolerance')" clearable />
                                </el-form-item>
                            </label>
                            <!-- Сув омборининг лойиҳа бўйича асосий кўрсаткичлари-->
                            <p class="indicator ">{{$t('canals_general_indicator')}}:</p>
                            <label class="item">
                                <p>{{$t('all_length')}}</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="total_length.value">
                                        <el-input name="all_length" type="number" :disabled="disabled1" :placeholder="$t('all_length')" v-model="res1.total_length.value" clearable />
                                    </el-form-item>
                                    <el-form-item prop="total_length.unit">
                                        <el-select name="total_length_unit" :disabled="disabled1" v-model="res1.total_length.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <!-- Сув омборининг лойиҳа бўйича асосий кўрсаткичлари-->
                            <p class="indicator2">{{$t('thet_is')}}:</p>
                            <label class="item">
                                <p> <i>{{$t('concrete_coating')}}</i> </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="concrete_coating.value">
                                        <el-input  name="concrete_coating" type="number" :disabled="disabled1" v-model="res1.concrete_coating.value" :placeholder="$t('concrete_coating')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="concrete_coating.unit">
                                        <el-select name="concrete_coating_unit" :disabled="disabled1" v-model="res1.concrete_coating.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p><i>{{$t('soil_road')}}</i> </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="soil_road.value">
                                        <el-input name="soil_road_value" type="number" :disabled="disabled1" v-model="res1.soil_road.value" :placeholder="$t('soil_road')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="soil_road.unit">
                                        <el-select name="soil_road_unit" :disabled="disabled1" v-model="res1.soil_road.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p><i>{{$t('tubular')}}</i></p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="tubular.value">
                                        <el-input name="tubular_value" type="number" :disabled="disabled1" v-model="res1.tubular.value" :placeholder="$t('tubular')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="tubular.unit">
                                        <el-select name="tubular_unit" :disabled="disabled1" v-model="res1.tubular.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <!-- ------------------------------ -->
                            <label class="item">
                                <p>{{$t('max_water_spend')}} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="max_water_consumption.value">
                                        <el-input name="max_water_spend" type="number" :disabled="disabled1" v-model="res1.max_water_consumption.value" :placeholder="$t('max_water_spend')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="max_water_consumption.unit">
                                        <el-select name="max_water_spend_unit" :disabled="disabled1" v-model="res1.max_water_consumption.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_2" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{$t('normal_water_consumption')}} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="normal_water_consumption.value">
                                        <el-input name="normal_water_consumption" type="number" :disabled="disabled1" v-model="res1.normal_water_consumption.value" :placeholder="$t('normal_water_consumption')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="normal_water_consumption.unit">
                                        <el-select name="normal_water_consumption_unit" :disabled="disabled1" v-model="res1.normal_water_consumption.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_2" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                        </el-col>
                        <el-col :md="8">

                            <label class="item">
                                <p>{{$t('min_water_consumption')}} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="min_water_consumption.value">
                                        <el-input name="min_water_consumption" type="number" :disabled="disabled1" v-model="res1.min_water_consumption.value" :placeholder="$t('min_water_consumption')" clearable />
                                    </el-form-item>
                                    <el-form-item prop="min_water_consumption.unit">
                                        <el-select name="min_water_consumption_unit" :disabled="disabled1" v-model="res1.min_water_consumption.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_2" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{$t('total_area_irrigated')}}, га</p>
                                <el-form-item class="flex-auto w-full" prop="total_area_irrigated.value">
                                    <el-input name="total_area_irrigated" type="number" :disabled="disabled1" v-model="res1.total_area_irrigated" :placeholder="$t('total_area_irrigated')" clearable />
                                </el-form-item>

                            </label>

                            <label class="item">
                                <p>{{$t('number_of_hydraulic_structures')}}</p>
                                <el-form-item class="flex-auto w-full" prop="number_of_hydraulic_structures">
                                    <el-input name="number_of_hydraulic_structures" type="number" :disabled="disabled1" v-model="res1.number_of_hydraulic_structures" :placeholder="$t('number_of_hydraulic_structures')" clearable />
                                </el-form-item>
                            </label>
                            <p class="indicator2">{{$t('thet_is')}}:</p>
                            <label class="item">
                                <p><i>{{$t('water_barrier_structures')}}</i> </p>
                                <el-form-item class="flex-auto w-full" prop="water_barrier_structures">
                                    <el-input name="water_barrier_structures" type="number" :disabled="disabled1" v-model="res1.water_barrier_structures" :placeholder="$t('water_barrier_structures')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p><i>{{$t('water_extraction_structures')}}</i></p>
                                <el-form-item class="flex-auto w-full" prop="water_extraction_structures">
                                    <el-input name="water_extraction_structures" type="number" :disabled="disabled1" v-model="res1.water_extraction_structures" :placeholder="$t('water_extraction_structures')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> <i> {{$t('accident_discharges')}} </i></p>
                                <el-form-item class="flex-auto w-full" prop="accident_discharges">
                                    <el-input name="accident_discharges" type="number" :disabled="disabled1" v-model="res1.accident_discharges" :placeholder="$t('accident_discharges')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> <i> {{$t('other_building_count')}} </i></p>
                                <el-form-item class="flex-auto w-full" prop="other_structures">
                                    <el-input name="other_building_count" type="number" :disabled="disabled1" v-model="res1.other_structures" :placeholder="$t('other_building_count')" clearable />
                                </el-form-item>
                            </label>
                            <!-- ---------------------- -->
                            <label class="item">
                                <p> {{$t('finalconclusion')}} </p>
                                <el-form-item prop="finalconclusion">
                                    <el-select name="finalconclusion" :disabled="disabled1" v-model="res1.finalconclusion" class="w-full" clearable :placeholder="$t('finalconclusion')">
                                        <el-option v-for="item in dictionary.finalconclusion" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <div class="actions mt-10">
                                <div class=" flex justify-end w-full" v-if="role !== 'admin' &&  !disabled1">
                                    <!-- <el-button v-if="res1.id" class="primary-btn"><i class="el-icon-edit mr-1"></i>Ўзгартириш</el-button> -->
                                    <div>
                                        <el-button id="save-button"  @click.native="save1()" class="primary-btn " style="width:150px"><i class="el-icon-document-checked mr-1"></i> {{$t('save')}} </el-button>
                                    </div>

                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            disabled1: false,
            disabled2: false,
            isCreated2: false,
            loading: false,
            activeNames: "1",
            current_district: {},
            obj: {},
            isCreated: false,
            res1: {
                name_cyr: '',
                address: '',
                year_of_construction: '',
                date_of_accept: '',
                organization_name: '',
                user_organization_name: '',
                earthquake_tolerance: '',
                region: '',
                district: "",
                water_source: '',
                reservoir_class: '',
                total_area_irrigated: '',
                number_of_hydraulic_structures: '',
                water_barrier_structures: '',
                water_extraction_structures: '',
                accident_discharges: '',
                other_structures: '',
                buildingseismicity: '',
                total_length: {
                    value: null,
                    unit: 12
                },
                concrete_coating: {
                    value: null,
                    unit: 12
                },
                soil_road: {
                    value: null,
                    unit: 12
                },
                tubular: {
                    value: null,
                    unit: 12
                },
                max_water_consumption: {
                    value: null,
                    unit: 13
                },
                normal_water_consumption: {
                    value: null,
                    unit: 13
                },
                min_water_consumption: {
                    value: null,
                    unit: 13
                },
                finalconclusion: ''
            },
            rules1: {
                name_cyr: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                address: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                year_of_construction: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                date_of_accept: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                user_organization_name: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                earthquake_tolerance: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                region: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                district: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                water_source: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                reservoir_class: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                total_area_irrigated: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                number_of_hydraulic_structures: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                water_barrier_structures: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                water_extraction_structures: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                accident_discharges: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                other_structures: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                buildingseismicity: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                total_length: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                concrete_coating: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                soil_road: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                tubular: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                max_water_consumption: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                normal_water_consumption: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                min_water_consumption: {
                    value: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    unit: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                },
                finalconclusion: {
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                }

            },
        }
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },
        units() {
            return this.$store.state.units;
        },
        role() {
            return this.$store.state.role;
        },
        page() {
            return this.$route.query.id || '';
        },

    },
    watch: {
        'res1.region': function () {
            this.current_district = [];

            this.loading = true;
            axios.get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted() { 
        if (this.$route.params.id) {
            this.disabled1 = this.$route.params.edit == '1' ? true : false
            if (this.role === 'admin') {
                this.disabled1 = true;
            }
            this.__GET()
        }

    },
    methods: {
        __GET() {
            if (this.$route.params.id) {
                this.loading = true;
                axios.get(`/reservoir/canals/${this.$route.params.id}`)
                    .then((response) => {
                        response.data.year_of_construction = String(response.data.year_of_construction)
                        this.res1 = response.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            } else {

            }
        },
        save1() {
            this.$refs.res1.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (!this.$route.params.id) {
                        axios.post('/reservoir/canals/', this.res1)
                            .then((response) => {
                                axios.post(`/reservoir/canals/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
                                    })
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.sucsess_save('sucsess_save'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: '/reservoir/canals'
                                })
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_create'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    } else {
                        axios.patch(`/reservoir/canals/${this.$route.params.id}/`, this.res1)
                            .then((response) => {
                                axios.post(`/reservoir/canals/${response.data.id}/generate/`)
                                    .then(res => { 
                                        this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
                                    })

                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: '/reservoir/canals'
                                })
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    }
                }

            })
        },
        regionChanged() {
            this.current_district = [];
            this.spec1.district = [];
            this.getDistrict();
        },
        getDistrict() {
            this.loading = true;
            axios
                .get(`/dictionary/district/?region=${this.res1.region}`)
                .then((response) => {
                    this.current_district = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.indicator {
    font-weight: 500;
    font-size: 18px;
    padding: 15px 0px 5px 0;
    border-bottom: 1px solid #004787;
}

.indicator2 {
    font-weight: 500;
    font-size: 18px;
    padding: 20px 0px 0px 0;

}

.el-collapse-item__header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #004787 !important;
    padding: 15px 0;
    border-bottom: none;
}

.build-tab {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    li {
        cursor: pointer;
        width: 400px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #d4d4d4;
        font-size: 17px;
        line-height: 24px;
        color: #004787;
        position: relative;
        user-select: none;

        &.active {
            border-bottom: solid 3px #004787;
        }
    }
}

.uform .Form {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

.uform .el-form-item {
    margin-bottom: 0 !important;
}
</style>
